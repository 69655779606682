import React, {Component} from 'react';
import './login.scss';
import $ from 'jquery';
import {NotificationManager} from 'react-notifications';
import {connect} from 'react-redux';
import {login} from '../../redux/userActions';
import {Redirect} from 'react-router-dom';

// import store from '../../redux/store';

class Login extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('component did update method is called!');
    }

    postLogin = () => {
        let email = $('input[name="email"]').val();
        let password = $('input[name="password"]').val();

        if (!email || !password) {
            NotificationManager.warning('', 'イメイルとぺスワードは必須項目です。', 3000);
            return;
        }

        this.props.dispatchLogin(email, password);

        console.log(this.props.user);
        console.log(this.store.getState());


        // this.props.history.push('/');
    };

    render() {
        if (this.props.user) {
            return <Redirect to="/"/>
        }

        return (
            <div className="login-component">
                <table className="login-table">
                    <tbody>
                    <tr>
                        <th>イメイル:</th>
                        <td><input type="text" className="form-control" name="email"/></td>
                    </tr>
                    <tr>
                        <th>ぺスワード:</th>
                        <td><input type="password" className="form-control" name="password"/></td>
                    </tr>
                    </tbody>
                </table>
                <div className="login-btn-wrap">
                    <button className="btn btn-dark" onClick={this.postLogin}>ログイン</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = {
    dispatchLogin: (email, password) => login(email, password),
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
