import React, {Component} from 'react';
import './albums.scss';

class Albums extends Component {
    render() {
        return (
            <div className="albums-component">
                <div className="text-center">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSex__B5rsA0J9ZWwQ81SasXvpneFgDfIG4Bb6dZO-OOOS4NvA/viewform?usp=sf_link"
                       target="_blank"
                       className="btn btn-cart"
                    ><i className="fa fa-shopping-cart"/> ご購入はこちらをクリック</a>
                </div>

                <div className="album-item">
                    <div><i className="fa fa-compact-disc"/> <b>Where I have to go</b> <span>2023</span></div>
                    <img src="/images/where_i_have_to_go_for_web.jpg" width="200" className="album_image"/>
                    <div className="song-list">
                        1 Whear I have to go ? <br/>
                        2 夜 <br/>
                        3 ありったけの愛を… <br/>
                        4 甘い誘惑 <br/>
                        5 SMILE
                    </div>
                </div>

                <div class="album-item">
                    <div><i className="fa fa-compact-disc"/> <b>Fight Myself</b> <span>2021</span></div>
                    <img src="/images/2021_album.jpg" width="200" className="album_image"/>
                    <div className="song-list">
                        1 Fight myself <br/>
                        2 Thankful <br/>
                        3 私の居場所～Acoustic Ver.～
                    </div>
                </div>
                <div className="album-item">
                    <div><i className="fa fa-compact-disc"/> <b>Ballard Best ~Listen from your heart~</b>
                        <span>2020</span></div>
                    <img src="/images/mika_ballard_best.jpg" width="200" className="album_image"/>
                    <div className="song-list">
                        1 今ここにある確かなモノ <br/>
                        2 もういいよ <br/>
                        3 月の光 <br/>
                        4 もう少し…あと少し… <br/>
                        5 SABOTEN <br/>
                        6 AM3:00 <br/>
                        7 I'm so in love <br/>
                        8 Until you hear my voice <br/>
                        9 Dear Mom&Dad <br/>
                        10 夏の花 <br/>
                        11 永遠に… <br/>
                        12 燃ゆる想い ~Piano Ver.~
                    </div>
                </div>
                <div className="album-item">
                    <div><i className="fa fa-compact-disc"/> <b>燃ゆる想い</b> <span>2020</span></div>
                    <img src="/images/album_mo_s.jpg" width="200" className="album_image"/>
                    <div className="song-list">
                        1 燃ゆる想い <br/>
                        2 Never give up
                    </div>
                </div>
                <div className="album-item">
                    <div><i className="fa fa-compact-disc"/> <b>もう一度</b> <span>2019</span></div>
                    <img src="/images/mou_s.jpg" width="200" className="album_image"/>
                    <div className="song-list">
                        1 もう一度... <br/>
                        2 永遠に
                    </div>
                </div>
                <div className="album-item">
                    <div><i className="fa fa-compact-disc"/> <b>Feel the beat (UPテンポ BEST)</b> <span>2017</span></div>
                    <img src="/images/feel_s.jpg" width="200" className="album_image"/>
                    <div className="song-list">
                        1 Listen to my heart <br/>
                        2 Brand new step <br/>
                        3 Thank you Best Friend <br/>
                        4 I wanna get your heart <br/>
                        5 Don't cover the heart <br/>
                        6 私の居場所 <br/>
                        7 If I could said... <br/>
                        8 過ち <br/>
                        9 Please love me <br/>
                        10 You are drive me crazy <br/>
                        11 Don't be afraid
                    </div>
                </div>
                <div className="album-item">
                    <div><i className="fa fa-compact-disc"/> <b>Sweet Dream</b> <span></span></div>
                    <img src="/images/sweet_s.jpg" width="200" className="album_image"/>
                </div>
                <div className="album-item">
                    <div><i className="fa fa-compact-disc"/> <b>Brand new Step</b> <span></span></div>
                    <img src="/images/brand_new_step_s.jpg" width="200" className="album_image"/>
                </div>
                <div className="album-item">
                    <div><i className="fa fa-compact-disc"/> <b>Listen to my heart</b> <span></span></div>
                    <img src="/images/listen_s.jpg" width="200" className="album_image"/>
                </div>
                <div className="album-item">
                    <div><i className="fa fa-compact-disc"/> <b>Summer Flower</b> <span></span></div>
                    <img src="/images/summer_s.jpg" width="200" className="album_image"/>
                </div>

                <br className="clear"/>

                <div className="text-center">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSex__B5rsA0J9ZWwQ81SasXvpneFgDfIG4Bb6dZO-OOOS4NvA/viewform?usp=sf_link"
                       target="_blank"
                       className="btn btn-cart"
                    ><i className="fa fa-shopping-cart"/> ご購入はこちらをクリック</a>
                </div>
            </div>
        );
    }
}

export default Albums;
