import React, { Component } from 'react';
import './goods.scss';

class Goods extends Component {
    render() {
        return (
            <div className="goods-component">
                <div className="text-center">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSf2ifz6IqTByWA7WLvbSd3-2_o4Q9KJ65R6L25YJxbHcjIZ3A/viewform?usp=sf_link"
                        target="_blank"
                        className="btn btn-cart"
                    ><i className="fa fa-shopping-cart" /> Mika2025年カレンダーご購入をクリック</a>
                </div>

                <div className="goods-item">
                    <div><i className="fa fa-heart" /> <b>Mika2025年カレンダー</b> <span></span></div>
                    <img src="/images/200260.jpg" className="goods_image" />
                </div>


                <br className="clear" />
                <hr />

                <div className="text-center">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSex__B5rsA0J9ZWwQ81SasXvpneFgDfIG4Bb6dZO-OOOS4NvA/viewform?usp=sf_link"
                        target="_blank"
                        className="btn btn-cart"
                    ><i className="fa fa-shopping-cart" /> ご購入はこちらをクリック</a>
                </div>

                <div className="goods-item">
                    <div><i className="fa fa-heart" /> <b>「紅月」 Photo Book</b> <span></span></div>
                    <img src="/images/photobook_2_s.jpg" className="goods_image" />
                </div>

                {/* <div className="goods-item">
                    <div><i className="fa fa-heart"/> <b>1st Photo Book</b> <span></span></div>
                    <img src="/images/photobook_1_s.jpg" className="goods_image"/>
                </div> */}

                <div className="goods-item">
                    <div><i className="fa fa-heart" /> <b>オリジナル タオル</b> <span></span></div>
                    <img src="/images/towel_s.jpg" className="goods_image" />
                </div>

                <br className="clear" />

                <div className="text-center">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSex__B5rsA0J9ZWwQ81SasXvpneFgDfIG4Bb6dZO-OOOS4NvA/viewform?usp=sf_link"
                        target="_blank"
                        className="btn btn-cart"
                    ><i className="fa fa-shopping-cart" /> ご購入はこちらをクリック</a>
                </div>
            </div>
        );
    }
}

export default Goods;
