import React, {Component} from 'react';
import {Link, BrowserRouter as Router, Route, Switch} from 'react-router-dom';

class CategoryEdit extends Component {
    componentDidMount() {
        let params = this.props.match.params;
        this.id = (isNaN(params.id)) ? params.id : null;
    }

    render() {
        return (
            <div>
                <h2>{!this.id && <span>New </span>}Category</h2>
                <div className="form-group">
                    <label htmlFor="name">Name :</label>
                    <input type="text" className="form-control" id="name"/>
                </div>
            </div>

        );
    }
}

export default CategoryEdit;
