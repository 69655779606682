import React, {Component} from 'react';
import logo from './logo.svg';
import './App.scss';
import $ from 'jquery';
import {BrowserRouter as Router, Link, Route, Switch} from 'react-router-dom';
import ProductMain from './_admin/products/Main';
import Home from './components/home/home';
import Albums from './components/albums/albums';
import Goods from './components/goods/goods';
import Events from './components/events/events';
import Login from './components/login/login';
import Contact from './components/contact/contact';
import {NotificationContainer} from 'react-notifications';
import {connect, Provider} from 'react-redux';
import store from './redux/store';
import Charity from './components/charity/charity';

class App extends Component {
    constructor(props) {
        super(props);
        // this.state = {apiResponse: ''};

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('component did update method is called!');
    }

    componentDidMount() {
        this.state = this.state || store.getState();
        console.log(this.state.user);
    }

    // callAPI() {
    //     $.get('http://localhost:9000/testAPI')
    //         .done(res => this.setState({apiResponse: res}))
    //         .fail(err => err);
    //
    // }
    //
    // componentDidMount() {
    //     this.callAPI();
    // }

    render() {
        return (
            <Provider store={store}>
                <Router>
                    <div className="App container">
                        <div className="row">
                            <div className="logo col-sm">
                                <img src="/images/top_logo.jpg" className="top_logo"/>
                                {/*<div className="logo-sub"><i className="far fa-heart red-heart"></i> Mika diva official website <i*/}
                                {/*    className="far fa-heart red-heart"></i></div>*/}
                            </div>
                            {/*<div className="logo col-sm text-right account-btn-wrap">*/}
                            {/*    {this.user*/}
                            {/*        ? <Link to="/logout"><i className="fas fa-user-circle"></i> ログアウト</Link>*/}
                            {/*        : <Link to="/login"><i className="fas fa-user-circle"></i> ログイン</Link>*/}
                            {/*    }*/}
                            {/*</div>*/}
                        </div>
                        <ul className="nav">
                            <li><Link to="/"><i className="fa fa-home"/> Mika</Link></li>
                            <li><Link to="/albums"><i className="fa fa-compact-disc"/> CD</Link></li>
                            <li><Link to="/goods"><i className="fa fa-heart"/> Goods</Link></li>
                            <li><Link to="/events"><i className="fa fa-icons"/> Events</Link></li>
                            <li><Link to="/charity"><i className="fa fa-hand-holding-heart"></i> チャリティー</Link>
                            </li>
                            <li><Link to="/contact"><i className="fa fa-envelope"/> Contact</Link></li>
                        </ul>
                        <Switch>
                            <Route exact path="/" component={Home}/>
                            <Route path="/albums" component={Albums}/>
                            <Route path="/goods" component={Goods}/>
                            <Route path="/events" component={Events}/>
                            <Route path="/login" component={Login}/>
                            <Route path="/charity" component={Charity}/>
                            <Route path="/contact" component={Contact}/>
                            <Route render={() => <h2>Page not found.</h2>}/>
                        </Switch>
                        <div className="footer">MikaDiva. All rights reserved.</div>
                    </div>
                    <NotificationContainer/>
                </Router>
            </Provider>
        );
    }
}

export default App
