import {UPDATE_USER, LOGOUT} from '../userActions';

const initialState = null;

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_USER: {
            // return action.user;
            return Object.assign({}, action.user)
        }
        case LOGOUT: {
            return initialState
        }

        default:
            return state;
    }
};

export default userReducer;