import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Admin from './_admin/Admin';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import * as serviceWorker from './serviceWorker';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-notifications/lib/notifications.css';

window.$ = window.jQuery = $;

if (window.location.pathname.indexOf('/_admin/') === 0) {
    ReactDOM.render(<Admin/>, document.getElementById('root'));
} else {
    ReactDOM.render(<App/>, document.getElementById('root'));
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
