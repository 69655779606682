import React, {Component} from 'react';
import {Link, BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Categories from './Categories';
import CategoryEdit from './CategoryEdit';

class Main extends Component {
    render() {
        return (
            <Router>
                <div className="container-fluid">
                    <div className="row flex-nowrap">
                        <div className="left-menu col-md-3 col-xl-2">
                            <nav>
                                <ul>
                                    <li>
                                        <Link to={`${this.props.match.url}/categories`}>Categories</Link>
                                    </li>
                                    <li>
                                        <Link to={`${this.props.match.url}`}>Products</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="right-content col-md-9 col-xl-10">
                            <Switch>
                                <Route exact path={`${this.props.match.url}`} render={() => <h2>Products List</h2>}/>
                                <Route exact path={`${this.props.match.url}/categories`}
                                       component={Categories}/>
                                <Route exact path={`${this.props.match.url}/categories/:id`} component={CategoryEdit}/>
                                <Route render={() => <h2>Page not found.</h2>}/>
                            </Switch>
                        </div>
                    </div>
                </div>
            </Router>
        );
    }
}

export default Main;
