export const ADD_CATEGORY = 'ADD_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';

export function addCategory(category) {
    // Todo: update store
    return {
        type: ADD_CATEGORY,
        category,
    }
}

export function updateCategory(category) {
    // Todo: update store
    return {
        type: UPDATE_CATEGORY,
        category,
    }
}

export function deleteCategory(category) {
    // Todo: update store
    return {
        type: DELETE_CATEGORY,
        category,
    }
}