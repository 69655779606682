import React, {Component} from 'react';
import {Link, BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import $ from 'jquery';
import {connect} from 'react-redux';

// import mapStateToProps from 'react-redux/es/connect/mapStateToProps';


class Categories extends Component {
    constructor(props) {
        super(props);
        // this.state = {
        //     categoryList: [
        //         {
        //             id: 1,
        //             name: 'Category1'
        //         },
        //         {
        //             id: 2,
        //             name: 'Category2'
        //         }
        //     ]
        // };
    }

    // callAPI() {
    //     $.get('http://localhost:9000/testAPI')
    //         .done(res => this.setState({categoryList: res}))
    //         .fail(err => err);
    // }

    // componentDidMount() {
    //     this.callAPI();
    // }

    _resetForm = () => {
        const $categoryName = window.$('.categoryName');
        $categoryName.removeClass('is-invalid');
        $categoryName.parent().find('.invalid-feedback').text('');
    };

    openEditWindow = (id) => {
        this._resetForm();
        window.$('.categoryEditModal').modal('show');

    };

    saveCategory = () => {
        const $categoryName = window.$('.categoryName');
        let categoryName = $categoryName.text();
        if (categoryName) {
            // post
        } else {
            $categoryName.addClass('is-invalid');
            $categoryName.parent().find('.invalid-feedback').text('Category name is required.');
        }
    };

    render() {
        return (
            <div>
                <h2>Categories</h2>
                <div className="text-right">
                    <button type="button" className="btn btn-sm btn-dark"
                            onClick={this.openEditWindow}
                    ><i className="fas fa-plus"/> Add
                    </button>
                </div>
                <ul>
                    {
                        this.props.categories.map((category) => {
                            return <li>{category.name}</li>
                        })
                    }
                </ul>

                <div className="modal categoryEditModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Category Name</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <input type="text" name="categoryName"
                                       className="form-control categoryName"/>
                                <div className="invalid-feedback"/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={this.saveCategory}>Save
                                    changes
                                </button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    categories: state.category.categories
});

export default connect(mapStateToProps)(Categories)
// export default Categories;
