import React, { Component } from 'react';
import './home.scss';

class Home extends Component {
    render() {
        return (
            <div className="home-component">
                <div className="text-danger" style={{
                    fontSize: '22px',
                    fontFamily: 'Lucida Calligraphy,Comic Sans MS,Lucida Console'
                }}>News
                </div>
                <div className="news-body">

                    <div style={{ margin: "00px auto;", textAlign: "center" }}>
                        <img src="/images/where_i_have_to_go_for_web.jpg" width="250"
                            style={{ borderRadius: "10px" }}
                        />
                    </div>

                    <div style={{ textAlign: "center" }}>
                        2023年5月13日New Mini-album Release.<br />
                        ご購入はこちらから
                        <a className='btn btn-danger btn-sm' style={{ color: "white" }} href='/albums' >SHOP</a>
                    </div>
                </div>

                <br />

                <img className="d-block w-100" src="images/home.jpg" alt="Mika diva" />
                {/*<div id="home-carousel" className="carousel slide" data-ride="carousel">*/}
                {/*    <div className="carousel-inner">*/}
                {/*        <div className="carousel-item active">*/}
                {/*            <img className="d-block w-100" src="images/mika_pococha_logo.jpg" alt="Mika diva"/>*/}
                {/*        </div>*/}
                {/*        <div className="carousel-item">*/}
                {/*            <img className="d-block w-100" src="images/mika-1.jpg" alt="Mika diva"/>*/}
                {/*        </div>*/}
                {/*        <div className="carousel-item">*/}
                {/*            <img className="d-block w-100" src="images/mika-2.jpg" alt="Mika diva"/>*/}
                {/*        </div>*/}
                {/*        <div className="carousel-item">*/}
                {/*            <img className="d-block w-100" src="images/mika-3.jpg" alt="Mika diva"/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <a className="carousel-control-prev" href="#home-carousel" role="button"*/}
                {/*       data-slide="prev">*/}
                {/*        <span className="carousel-control-prev-icon" aria-hidden="true"></span>*/}
                {/*        <span className="sr-only">Previous</span>*/}
                {/*    </a>*/}
                {/*    <a className="carousel-control-next" href="#home-carousel" role="button"*/}
                {/*       data-slide="next">*/}
                {/*        <span className="carousel-control-next-icon" aria-hidden="true"></span>*/}
                {/*        <span className="sr-only">Next</span>*/}
                {/*    </a>*/}
                {/*</div>*/}
                <br />
                <div>
                    小学生の頃からプロのsingerを目指し、12歳からボイトレを本格始動。15歳でイギリスインディーズよりデビューCD「Secret Garden」発売。翌年16歳にGIZA
                    studioより「長谷実果」の名前で日本メジャーデビューを果たす。その後19歳で語学と音楽留学の為単身渡米。NewYorkとLosAngeleｓで約10年間ボイトレやLive活動後、帰国して「Mika」の名で音楽活動と共に配信アプリ(Pococha)とボイストレーナーとしても活動しています。
                </div>

                <div className="links">
                    <a href="https://www.pococha.com/app/users/3b4293e5-b640-44b3-abe1-452a8fdf5641" target="_blank">
                        <img className="d-block" src="images/pococha.svg" alt="Pococha" />
                    </a>
                </div>

                <div className="links">
                    <a href="https://twitter.com/MikaDivaDream?t=3cOGCyz0zBjyqQpCLFVLNA&s=06" target="_blank">
                        <img className="d-block twitter_logo" src="images/twitter-x-logo.png" alt="Twitter" />
                        {/*<img className="d-block twitter_logo" src="images/twitter.svg" alt="Twitter"/>*/}
                    </a>
                </div>

                <div className="links">
                    <a href="https://www.instagram.com/Mika_Diva_Dream_" target="_blank">
                        <img className="d-block insta_logo" src="images/instagram.svg" alt="Instagram" />
                    </a>
                </div>

                <div className="links">
                    <a href="https://youtube.com/channel/UC1iswyfBAQQiojSgTPjvqKg" target="_blank">
                        <img className="d-block" src="images/youtube.svg" alt="Youtube" />
                    </a>
                </div>

                <div className="links">
                    <a href="https://www.tiktok.com/@mikadivadream" target="_blank">
                        <img className="d-block tiktok_logo" src="images/tiktok_logo.png" alt="Tiktok" />
                    </a>
                </div>

                <br />

            </div>
        );
    }
}

export default Home;
