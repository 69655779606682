import React, {Component} from 'react';
import {Link, BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ProductMain from './products/Main';
import './Admin.scss';
import {connect, Provider} from 'react-redux';
import store from '../redux/store';

class Admin extends Component {
    constructor(props) {
        super(props);
        this.ADMIN_PATH = '/_admin/';
        this.user = store.getState().accountReducer
    }

    logout = () => {
        console.log('logout')
    };

    render() {
        return (
            <Provider store={store}>
                <Router>
                    <div className="admin">
                        <header className="admin-header container-fluid">
                            <div className='row'>
                                <div className="col-sm-8 admin-logo-text">Administrator</div>
                                <div className="col-sm-4 text-right">
                                    {this.user
                                        ? <a>[Logout]</a>
                                        : <a>[Login]</a>
                                    }
                                </div>
                            </div>
                        </header>
                        <div className="nav-wrap">
                            <nav>
                                <ul>
                                    <li>
                                        <Link to={`${this.ADMIN_PATH}`}>Dashboard</Link>
                                    </li>
                                    <li>
                                        <Link to={`${this.ADMIN_PATH}members`}>Members</Link>
                                    </li>
                                    <li>
                                        <Link to={`${this.ADMIN_PATH}products`}>Products</Link>
                                    </li>
                                    <li>
                                        <Link to={`${this.ADMIN_PATH}orders`}>Orders</Link>
                                    </li>
                                    <li>
                                        <Link to={`${this.ADMIN_PATH}settings`}>Settings</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <Switch>
                        <Route exact path={`${this.ADMIN_PATH}`} render={() => <h2>Dashboard</h2>}/>
                        <Route path={`${this.ADMIN_PATH}products`} component={ProductMain}/>
                        <Route render={() => <h2>Page not found.</h2>}/>
                    </Switch>
                </Router>
            </Provider>
        );
    }
}

// const mapStateToProps = (state) => ({
//     account: state.accountReducer
// });

// export default connect(mapStateToProps)(Admin)
export default Admin;

