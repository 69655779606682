import React, {Component} from 'react';
import './charity.scss';

class Charity extends Component {
    render() {
        return (
            <div className="events-component">
                <div>
                    <div className="event">
                        <a href="https://peace-wanko.jp/facility/ikoma.html" target="_blank">
                        <img src="/images/biwako-center.png" style={{width: '100%', marginTop: '10px'}}/>
                        </a>
                        <br/>
                        ピースワンコ・ジャパン<br/>
                        <a href="https://peace-wanko.jp/facility/ikoma.html" target="_blank">生駒譲渡センター</a>に、物資の支援をしたいと思います🐕<br/><br/>

                        全国にはまだまだ人間のエゴで殺処分される悲しい命が溢れています。<br/>
                        その一方でそんな命を少しでも救おうと、活動して下さっている方々や施設があります。<br/><br/>

                        私の思いに賛同して下さる皆様と共に、そんなワンちゃん達を救うお手伝いをしたいと思います。<br/><br/>

                        10/28　のLIVEにて寄付金を募集致します。<br/><br/>

                        500円.1000円.3000円<br/><br/>

                        で、可能な額の寄付をお願い致します🙇<br/><br/>

                        後日皆様からの寄付を合算して、<a href="https://peace-wanko.jp/facility/ikoma.html" target="_blank">ピースワンコ・ジャパン生駒譲渡センター</a>に物資を送らせて頂きます🎁🐕<br/>
                    </div>

                    <div className="event">
                        <img src="/images/ukraine-flag.png" style={{width: '100px', marginTop: '10px'}}/>
                        <br/>
                        在日ウクライナ大使館へのご支援に賛同して下さった皆様ありがとうございました🙇✨ <br/>
                        お預かりした支援金は責任を持ってお振り込みさせて頂きました。 <br/>
                        <br/>
                        一刻も早く平和な世界になりますように。
                        <img src="/images/20220704.jpg" style={{width: '100%', marginTop: '10px'}}/>
                    </div>


                </div>
            </div>
        );
    }
}

export default Charity;
