import {ADD_CATEGORY, UPDATE_CATEGORY, DELETE_CATEGORY} from '../categoryActions';

const initialState = {
    categories: [
        {
            id: 1,
            name: 'Category 1'
        },
        {
            id: 2,
            name: 'Category 2'
        },
        {
            id: 3,
            name: 'Category 3'
        },
    ]
};

const categoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CATEGORY: {
            // only for test
            action.categories.id = state.categories.length + 1
            return {
                categories: [
                    ...state.categories,
                    action.category,
                ]
            }
        }
        case UPDATE_CATEGORY: {
            const newCategory = state.categories.map((category) => {
                if (category.id === action.category.id) {
                    return Object.assign({}, category, action.category)
                }
                return category
            });

            return {
                category: newCategory
            }
        }
        case DELETE_CATEGORY: {
            const filteredCategories = state.categories.filter(x => x.id !== action.category.id);
            return {
                categories: filteredCategories
            }
        }

        default:
            return state;
    }
};

export default categoriesReducer;