export const UPDATE_USER = 'UPDATE_USER';
export const LOGOUT = 'LOGOUT_USER';

export function login(email, password) {
    // Todo: update store

    // $.get('http://localhost:9000/testAPI')
    //     .done(res => this.setState({apiResponse: res}))
    //     .fail(err => err);

    let user = {
        'email': email,
    };

    return {
        type: UPDATE_USER,
        user,
    }
}

export function logout() {
    // Todo: update store
    return {
        type: LOGOUT,
    }
}