import React, {Component} from 'react';
import './contact.scss';

class Contact extends Component {
    render() {
        return (
            <div className="contact-component text-center">
                <div className="contact-text">
                    各種イベントやLIVE出演、ボイストレーニング受講希望等、お仕事のご依頼はこちらまでご連絡下さい。
                    <br/>
                    <i className="fa fa-at"/> <a
                    href="mailto:mikadivadream@gmail.com">mikadivadream@gmail.com</a>
                </div>
            </div>
        );
    }
}

export default Contact;
